.console-contact-content {
    width: 80%;
    margin: auto;
    height: 100%;
}

.contact-heading {
    padding: 30px;
}

.contact-heading h2 {
    font-size: 60px;
}

.contact-text {
    font-size: 30px;
    height: 55%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-item {
    margin-bottom: 30px;
}



/****** MEDIA QUERIES *****/

@media(max-width: 1400px) {}

@media(max-width: 1200px) {}

@media(max-width: 1400px) {}

@media(max-width: 992px) {}

@media(max-width: 768px) {

    .console-contact-content {
        width: 100%;
    }

    .contact-heading {
        padding: 20px 0;
    }

}

@media(max-width: 576px) {


    .contact-heading h2 {
        font-size: 40px;
    }

    .contact-text {
        font-size: 20px;
    }

    .external-link {
        overflow-wrap: break-word;
    }



}

@media(max-width: 430px) {}

@media(max-width: 350px) {

    .contact-heading h2 {
        font-size: 30px;
    }

    .contact-text {
        font-size: 18px;
    }

    .contact-email {
        font-size: 14px;
    }

}