.console-portfolio-content {
    width: 100%;
    height: 93%;
    margin: auto;
    overflow: auto;
}

.portfolio-heading {
    padding: 30px;
}

.portfolio-heading h2 {
    font-size: 60px;
}

.portfolio-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.portfolio-row-item {
    width: 30%;
    margin: 25px 25px;
    border-radius: 15px;
    transition: 0.3s ease-out;
}

.portfolio-row-item:hover {
    scale: 1.1;
    box-shadow: 0px 0px 10px 3px var(--greenForeground);
}

.portfolio-row-item:active {
    scale: 1.05;
}

.portfolio-img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    object-fit: cover;
    border-radius: 15px;
}


/****** MEDIA QUERIES *****/

@media(max-width: 1400px) {

    .portfolio-row-item {
        width: 40%;
    }

}

@media(max-width: 1200px) {}

@media(max-width: 992px) {

    .portfolio-row {
        display: block;
        width: 90%;
        margin: auto;
    }

    .portfolio-row-item {
        margin: auto;
        display: block;
        width: 80%;
        margin-bottom: 25px;
    }

}

@media(max-width: 768px) {}

@media(max-width: 576px) {

    h3 {
        font-size: 25px;
    }

    .portfolio-heading h2 {
        font-size: 35px;
    }

    .portfolio-row-item {
        width: 100%;
    }

}

@media(max-width: 430px) {

    h3 {
        font-size: 20px;
    }

    .portfolio-heading {
        padding: 30px 0;
    }

    .portfolio-heading h2 {
        font-size: 27px;
    }

}

@media(max-width: 350px) {

    h3 {
        font-size: 16px;
    }

    .portfolio-heading h2 {
        font-size: 20px;
    }

}