.console-about-content {
    width: 80%;
    margin: auto;
    height: 100%;
}

.about-heading {
    padding: 30px;
}

.about-heading h2 {
    font-size: 60px;
}

.about-text {
    font-size: 30px;
    height: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 50px;
}


/****** MEDIA QUERIES *****/

@media(max-width: 1400px) {

    .about-text {
        line-height: 30px;
    }

}

@media(max-width: 1200px) {}

@media(max-width: 992px) {

    .about-text {
        font-size: 24px;
    }

}

@media(max-width: 768px) {

    .about-heading {
        padding: 0;
    }

    .about-text {
        font-size: 20px;
        display: block;
    }

}

@media(max-width: 576px) {

    .console-about-content {

        overflow: auto;
        width: 100%;
        height: 90%;

    }

    .about-text {
        font-size: 18px;
    }

}

@media(max-width: 430px) {}

@media(max-width: 350px) {
    .about-heading h2 {
        font-size: 30px;
    }

    .about-text {
        font-size: 16px;
    }
}