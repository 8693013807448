.portfolio-item-container {
    width: 90%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.portfolio-item-img {
    width: 40%;
    border-radius: 15px;
}

.portfolio-item-img img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    object-fit: cover;
    border-radius: 15px;
}



/****** MEDIA QUERIES *****/

@media(max-width: 768px) {

    h2 {
        font-size: 35px;
    }

    .portfolio-item-container {
        font-size: 19px;
    }

    .portfolio-item-img {
        width: 60%;
    }

}

@media(max-width: 576px) {

    h2 {
        font-size: 35px;
    }

    .portfolio-item-container {
        font-size: 18px;
    }

    .portfolio-item-img {
        width: 55%;
    }

}

@media(max-width: 450px) {

    h2 {
        font-size: 25px;
    }

    .portfolio-item-container {
        display: block;
        overflow: auto;
        width: 100%;
        height: 90%;
    }

    .portfolio-item-img {
        width: 80%;
        margin: auto;
    }

}

@media(max-width: 350px) {

    h2 {
        font-size: 22px;
    }

    .portfolio-item-container {

        font-size: 16px;

    }

}