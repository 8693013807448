/*******VARIABLES******/
/* colours: */
:root {
  --yellow: #f8e837;
  --darkBlueBorder: #0e233a;
  --greenForeground: #23e126;
  --darkGreen: #003400;
  --black: #000000;
}


/****** STYLES ******/
html {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--yellow);
  font-size: 24px;
  font-family: 'Courier New', Courier, monospace;
}

h1 {
  color: var(--darkGreen);
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
}

h1,
h2 {
  margin: 0;
}

a:hover {
  cursor: url("./custom-cursor.png"), crosshair;
}


/************ SCROLL BAR ************/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--darkBlueBorder);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--yellow);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/****** CLASSES ******/

.Link:hover {
  cursor: url("./custom-cursor.png"), crosshair !important;
}

.outterFrame {
  background-color: var(--yellow);
  box-sizing: border-box;
  border: 20px solid var(--yellow);
  border-radius: 80px;
  height: 98vh;
  width: 100%;
  background-color: var(--darkBlueBorder);
  padding: 30px;

  text-align: center;
}

/* .consoleDisplay {
  background-color: var(--darkGreen);
  text-align: center;
  border-radius: 60px;
  height: 100%;
} */

.consoleDisplay {
  text-align: center;
  background-color: var(--greenForeground);
  /* border-radius: 60px 60px 0 0; */
  border-radius: 60px;
  height: 100%;
  cursor: url("./custom-cursor.png"), crosshair;
}


/* NAVBAR */
.nav-item {
  margin: 0 8px;
  padding: 0px 5px;
  /* border: 2px solid var(--greenForeground); */
  color: var(--darkGreen);
  text-decoration: none;
  transition: 0.2s ease-out;
}

.nav-item:hover {
  border: 3px solid var(--darkGreen);
  background-color: var(--darkGreen);
  color: var(--greenForeground);
  transform: translateY(1px);
  scale: 1.1;
}

.consoleContentContainer {
  background-color: var(--darkGreen);
  height: 87%;
  width: 98%;
  margin: auto;
  border-radius: 0 0 60px 60px;
  color: var(--greenForeground);
}


.external-link {
  color: var(--yellow);
  text-decoration: none;
}

.external-link:hover {
  color: var(--yellow);
  text-decoration: underline;
}



/********** IDs ********/

#mainHeader {
  height: 11%;
}

#mainNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  border-top: 2px solid var(--darkGreen);
  /* border-bottom: 2px solid var(--darkGreen); */
}





/****** MEDIA QUERIES *****/

@media(max-width: 1400px) {

  h1 {
    font-size: 40px;
  }

}

@media(max-width: 1200px) {

  h1 {
    font-size: 35px;
  }

}

@media(max-width: 992px) {

  body {
    font-size: 22px;
  }

  h1 {
    font-size: 30px;

  }

  .heading-container {
    padding: 0px 100px;
  }

  #mainHeader {
    height: 13%;
  }

  .consoleContentContainer {
    height: 85%;
  }

}

@media(max-width: 768px) {


  .heading-container {
    padding: 0px 30px;
  }

}

@media(max-width: 576px) {

  body {
    font-size: 20px;
  }

  h1 {
    font-size: 26px;
  }

}

@media(max-width: 473px) {

  body {
    font-size: 18px;
  }

  h1 {
    font-size: 26px;
  }

  #mainHeader {
    height: 15%;
  }

  .consoleContentContainer {
    height: 83%;
  }

}

@media(max-width: 430px) {
  h1 {
    font-size: 23px;
  }

  .nav-item {
    font-size: 16px;
    padding: 0px 1px;
    margin: 0 2px;
  }
}

@media(max-width: 426px) {

  h1 {
    font-size: 22px;
  }

  .nav-item {
    margin-top: 3px;
  }

}

@media(max-width: 350px) {

  h1 {
    font-size: 18px;
  }

  .heading-container {

    padding: 10px 10px 0px 10px;

  }

  .nav-item {
    margin-top: 3px;
    font-size: 9px;
  }

  #mainHeader {
    height: 18%;
  }

  .consoleContentContainer {
    height: 80%;
  }

}